

/*--------------------------------------------------------------
# General
--------------------------------------------------------------*/
	body {
		font-family: "Open Sans", sans-serif;
		color: #444444;
	}
	
	a {
		color: #6f8ac8;
		text-decoration: none !important;
	}
	
	a:hover {
		color: #6f8ac8;
	}
	
	h1, h2, h3, h4, h5, h6{
		font-family: 'Montserrat';
	}
	p{
		font-size: 14px;
		line-height: 1.8;
		font-family: 'Montserrat';
	}
	span{
		font-family: 'Montserrat';
	}

	/*this css use of font family start here*/
	.montserrat-regular{
		font-family: 'Montserrat';
	}
	.montserrat-semibold{
		font-family: 'Montserrat';
		font-weight: 600;
	}
	.montserrat-bold{
		font-family: 'Montserrat';
		font-weight: 700;
	}
	/*font family end here*/

	.card:hover{
		box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
	}

	.theme-color1{
		color: #6f8ac8 !important;
	}
	.theme-bg-color1{
		background-color: #6f8ac8 !important;
	}

	.large-btn{
		color: white !important;
		font-weight: 500 !important;
		background-color: #6f8ac8 !important;
		font-family: 'Montserrat' !important;
	}
	.small-btn{
		background-color: #6f8ac8 !important;
		font-weight: 500 !important;
		border-radius: 0px !important;
		color: white !important;
		border-radius: 5px !important;
		font-family: 'Montserrat' !important;
		font-size: 15px  !important;
	}
	.outline-btn{
		border: 2px solid #6f8ac8 !important;
		font-weight: 500 !important;
		color: #6f8ac8 !important;
		border-radius: 5px !important;
		font-family: 'Montserrat' !important;
		font-size: 15px  !important;
	}
	input[type=number]::-webkit-inner-spin-button, 
	input[type=number]::-webkit-outer-spin-button { 
		-webkit-appearance: none;
		-moz-appearance: none;
		appearance: none;
		margin: 0; 
	}
  
/*--------------------------------------------------------------
  # Disable aos animation delay on mobile devices
--------------------------------------------------------------*/
  @media screen and (max-width: 768px) {
	[data-aos-delay] {
	  transition-delay: 0 !important;
	}
  }


/*--------------------------------------------------------------
  # Navigation Menu
--------------------------------------------------------------*/

  	#Header .navbar{
		box-shadow: rgb(0 0 0 / 10%) 0px 3px 8px;
		border-bottom: 3px solid #6f8ac8;
		padding: 0px;  
	}

	#Header .navbar .nav-item{
		padding: 3px;
	}
	#Header ul li .nav-link{
		padding: 20px;
	}
	#Header ul li .nav-link:hover{
		background-color: #6f8ac8;
		color: #fff;
	}
  	#Header ul li a.active {
		background-color: #6f8ac8;
		color: #fff;
	}

/*--------------------------------------------------------------
  # Sections General
--------------------------------------------------------------*/
	.common-bg-img{
		background-image: url("../img/commom-bg-img.png"); 
		background-color: #cccccc; 
		background-position: center; 
		background-repeat: no-repeat; 
		background-size: cover; 
		background-attachment: fixed;
	}
	
	section {
		padding: 30px 0;
		overflow: hidden;
	}
	
	.section-bg {
		background-color: #f6f9fe;
	}
	
	.section-title {
		text-align: center;
		padding-bottom: 30px;
	}
	.section-title h3 {
		margin: 15px 0 0 0;
		font-size: 32px;
		font-weight: 700;
		color: #6f8ac8;
		margin-bottom: 20px;
	}

/*--------------------------------------------------------------
  # Hero Section
--------------------------------------------------------------*/
	#heroSection {
		position: relative;
		background-color: black;
		height: 100%;
		padding: 170px 0px 170px 0px;
		/* min-height: 25rem; */
		width: 100%;
		overflow: hidden;
	}
  
	#heroSection video {
		position: absolute;
		top: 50%;
		left: 50%;
		min-width: 100%;
		min-height: 100%;
		width: auto;
		height: auto;
		z-index: 0;
		-ms-transform: translateX(-50%) translateY(-50%);
		-moz-transform: translateX(-50%) translateY(-50%);
		-webkit-transform: translateX(-50%) translateY(-50%);
		transform: translateX(-50%) translateY(-50%);
	}

	#heroSection .overlay {
		position: absolute;
		top: 0;
		left: 0;
		height: 100%;
		width: 100%;
		background-color: black;
		opacity: 0.5;
		z-index: 1;
	}

	#heroSection .headding-div{
		position: relative;
		z-index: 2;
	}

	#heroSection h1{
		text-align: center;
		color: white;
		font-weight: 500;
		font-size: 50px;
		text-shadow: 1px 1px 1px #000;
	}

	#heroSection .main-search-input {
		background: #fff;
		border-radius: 1px;
		margin-top: 20px;
		box-shadow: 0px 0px 0px 6px rgb(52 45 45 / 42%);
	}
	
	#heroSection .fl-wrap {
		float: left;
		width: 100%;
		position: relative;
	}
	
	#heroSection .main-search-input-item input {
		float: left;
		border: none;
		width: 100%;
		height: 50px;
		padding-left: 20px;
	}
	
	#heroSection .main-search-button{
		background: #6f8ac8;
	}
	
	#heroSection .main-search-button {
		position: absolute;
		right: 0px;
		height: 50px;
		width: 120px;
		color: #fff;
		top: 0;
		border: none;
		border-top-right-radius: 0px;
		border-bottom-right-radius: 0px;
		cursor: pointer;
	}

	#heroSection .main-search-input-wrap {
		max-width: 800px;
		margin: 20px auto;
		position: relative;
	}
  
	@media only screen and (max-width: 768px){
		#heroSection .main-search-input {
			background: rgba(255,255,255,0.2);
			padding: 14px 20px 10px;
			border-radius: 10px;
			box-shadow: 0px 0px 0px 10px rgba(255,255,255,0.0);
		}

		#heroSection .main-search-input-item {
			width: 100%;
			border: 1px solid #eee;
			height: 50px;
			border: none;
			margin-bottom: 10px;
		}

		#heroSection .main-search-input-item input {
			border-radius: 6px !important;
			background: #fff;
		}

		#heroSection .main-search-button {
			position: relative;
			float: left;
			width: 100%;
			border-radius: 6px;
		}
	}
 
/*--------------------------------------------------------------
  # About
--------------------------------------------------------------*/
	#About .icon{
		font-size: 54px;
		text-align: left;
		padding-left: 13px;
		color: #6f8ac8;
	}
	#About .card:hover .icon{
		color: #fff;
	}

	#About .card:hover{
		background-color: #6f8ac8;
		color: #fff;
	}

	#About .about-details{
		padding: 28px;
		background-color: #6f8ac8;
		position: relative;
		left: 17%;
		top: 9%;
		color: white;
		box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
		border-radius: 40px 0px 40px 0px;
	}
  
/*--------------------------------------------------------------
  # Contact
--------------------------------------------------------------*/
	#Contact .info-box {
		color: #444444;
		text-align: center;
		box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
		padding: 20px 0 30px 0;
	}
	
	#Contact .info-box h3 {
		font-size: 20px;
		color: #777777;
		margin: 10px 0;
	}
	#Contact .info-box p {
		padding: 0;
		line-height: 24px;
		font-size: 14px;
		margin-bottom: 0;
	}

	@-webkit-keyframes animate-loading {
		0% {
		transform: rotate(0deg);
		}
		100% {
		transform: rotate(360deg);
		}
	}
	@keyframes animate-loading {
		0% {
		transform: rotate(0deg);
		}
		100% {
		transform: rotate(360deg);
		}
	}

/*--------------------------------------------------------------
  # Properties
--------------------------------------------------------------*/
  	#properties .form-control:focus {
		border-color: #8080807a !important;
		outline: none;
		box-shadow: none !important;	
	}
	#properties .property-price{
		float: right;
	}
	#properties ul li{
		overflow: auto;
		white-space: nowrap;
		display: inline;
		padding-right: 16px;
	}
	#properties ul{
		padding-left: 0rem !important;
	}

	#properties select option{	
		padding: 50px;
		height: 200px;
	}
	
	#properties  .img-hover-zoom {
		height: 100%;
		overflow: hidden; /* Removing this will break the effects */
	}

	#properties  .img-hover-zoom-quick-zoom img {
		transition: transform .5s, filter 1.5s ease-in-out;
	}
	  
	/* The Transformation */
	#properties  .img-hover-zoom-quick-zoom:hover img {
		filter: grayscale(0);
		transform: scale(1.7);
	}

/*--------------------------------------------------------------
  # Properties Details
--------------------------------------------------------------*/
	#propertyDetails .carousel-inner:before {
		content: '';
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		background: none !important;
		z-index: 1;
	}      
    /* #propertyDetails .carousel-indicators {
		top: 99%; 
		margin-left: 0% !important;
		margin-right: 0% !important;
	} */

	#propertyDetails .carousel-indicators {
		position: relative;
		right: 0 !important;
		bottom: 0;
		left: 0 !important;
		z-index: 2 !important;
		/* display: block; */
		display: flex;
		padding: 0;
		margin-right: 0; 
		margin-bottom: 0;
		margin-left: 0;
		text-align: center !important;
	}

	.carousel-control-next, .carousel-control-prev {
		top: 33% !important;
		align-items: unset !important;
	}

    #propertyDetails .carousel-indicators button {
		width: 100px !important;
	}

	#propertyDetails .carousel-inner {
		height: 0;
		padding-top: 55%;
	}
	
	#propertyDetails .carousel-item {
		position: absolute !important; 
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
	}
	
	#propertyDetails .carousel-item img {
		height: 100%; 
		object-fit: cover;
	}

	#propertyDetails .carousel-item{
		backface-visibility: hidden;
		transition: transform .0s ease-in-out  !important;
	}

	#propertyDetails .carousel-indicators [data-bs-target] {
		opacity: 1 !important;
    	transition: opacity .0s ease;

	}

	#propertyDetails .carousel-indicators img {
		float: left;
		width:  100px;
		height: 80px;
		object-fit: cover;
	}

/*--------------------------------------------------------------
  # Project 
--------------------------------------------------------------*/
	#Project .card-img-top {
		width: 100%;
		height: 15vw !important;
		border-radius: 0;
	}

/*--------------------------------------------------------------
  # Footer
--------------------------------------------------------------*/
  #footer {
	background: #fff;
	padding: 0 0 0 0;
	color: #fff;
	font-size: 14px;
	background: #191A1A;
  }
  #footer .footer-top {
	background-image: url('../img/footer-bg.png');
	padding: 60px 0 30px 0;
	background-position: center center !important;
    background-size: cover !important;
    background-repeat: no-repeat !important;
  }
  #footer .footer-top .footer-contact {
	margin-bottom: 30px;
  }
  #footer .footer-top .footer-contact h3 {
	font-size: 24px;
	margin: 0 0 15px 0;
	padding: 2px 0 2px 0;
	line-height: 1;
	font-weight: 700;
  }
  #footer .footer-top .footer-contact h3 span {
	color: #106eea;
  }
  #footer .footer-top .footer-contact p {
	font-size: 14px;
	line-height: 24px;
	margin-bottom: 0;
	font-family: "Roboto", sans-serif;
	/* color: #777777; */
	color: #fff;
  }
  #footer .footer-top h4 {
	font-size: 16px;
	font-weight: bold;
	/* color: #444444; */
	color: #fff;
	position: relative;
	padding-bottom: 12px;
  }
  #footer .footer-top .footer-links {
	margin-bottom: 30px;
  }
  #footer .footer-top .footer-links ul {
	list-style: none;
	padding: 0;
	margin: 0;
  }

  #footer .footer-top .footer-links ul li {
	padding: 10px 0;
	display: flex;
	align-items: center;
  }
  #footer .footer-top .footer-links ul li:first-child {
	padding-top: 0;
  }
  #footer .footer-top .footer-links ul a {
	/* color: #777777; */
	color: #fff;
	transition: 0.3s;
	display: inline-block;
	line-height: 1;
  }
  #footer .footer-top .footer-links ul a:hover {
	text-decoration: none;
	color: #fff;
  }
  #footer .footer-top .social-links a {
	font-size: 18px;
	display: inline-block;
	background: #fff;
	color: #fff;
	line-height: 1;
	padding: 8px 0;
	margin-right: 4px;
	border-radius: 4px;
	text-align: center;
	width: 36px;
	height: 36px;
	transition: 0.3s;
  }
  #footer .footer-top .social-links a:hover {
	background: #fff;
	color: #fff;
	text-decoration: none;
  }
  #footer .copyright {
	text-align: center;
  }
  #footer .credits {
	float: right;
	text-align: center;
	font-size: 13px;
	color: #fff;
  }
  @media (max-width: 768px) {
	#footer .copyright, #footer .credits {
	  float: none;
	  text-align: center;
	  padding: 2px 0;
	}
  }

/*--------------------------------------------------------------
  # Media Querys 
--------------------------------------------------------------*/
	@media (max-width: 768px) {
		#heroSection .headding-div{
			position: relative;
			top: 10%;
		}
		#properties .property-price{
			float: left;
			padding-top: 10px;
		}

		.hvWSgH {
			left: 85% !important;
		}

		#propertyDetails .carousel-indicators {
			top:0px !important;
		}

		#propertyDetails .carousel-indicators {
			display: flex !important;
			padding: 0;
			margin-right: 0;
			margin-bottom: 60px!important;	
		}
		#propertyDetails .carousel-indicators img {
			float: left;
			width:  50px;
			height: 50px;
			object-fit: cover;
		}
		#propertyDetails .carousel-inner {
			height: 0  !important;
			bottom: 0%;
		}

		#Project .card-img-top {
			width: 100%;
			height: 100% !important;
			border-radius: 0;
		}

		.res-float-left{
			float:left !important;
		}

		#About .about-details{
			left: 0%;
			top: -3%;
			bottom: 20%;		
		}

	}

  	/*ipad  media query*/
	@media screen and (max-width: 1024px) and (min-width: 760px) {
		#propertyDetails .carousel-inner {
			height: 0;
			padding-top: 100%;
		}
		#propertyDetails .carousel-indicators {
			top:0px !important;
		}

		#propertyDetails .carousel-indicators {
			display: flex !important;
			padding: 0;
			margin-right: 0;
			margin-bottom: 60px!important;	
		}

		#About .about-details{
			left: 0%;
			top: -3%;
			bottom: 20%;
		}
	}

	/*iPad Pro  media query*/
	@media only screen and (min-device-width: 1366px) and (max-device-height: 1024px) and (-webkit-min-device-pixel-ratio: 2)  and (orientation: landscape)  {

		#propertyDetails .carousel-indicators {
			display: flex !important;
			padding: 0;
			margin-right: 0;
			margin-bottom: 60px!important;	
		}

		#About .about-details{
			left: 0%;
			top: -3%;
			bottom: 20%;
		}
	}

	








	
	
	
	


  
  




  